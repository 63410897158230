<template>
    <div class="row">
        <div class="col-12" v-if="!isLoad">
            <div v-if="exist" class="row">
                <div class="col-12" v-if="question != null">
                    <div v-if="start" class="card d-block w-100 shadow-0 bg-transparent rounded-lg p-3 border-0 text-left">
                        <div class="card-body p-4 bg-white rounded-lg">
                            <div class="row justify-content-between px-2">
                                <div class="col-12 mb-4">
                                    <a @click="$router.go(-1)" href="javascript:void(0)" class="btn btn-outline-danger fw-500">Back</a>
                                </div>
                                <div class="col-md-9">
                                    <button v-for="(item, i) in question" :key="i" @click="current = i" :class="{'btn-current text-white' : current == i || submission.answer[i] != null, 'btn-outline-current text-current' : current != i}" class="btn w50 h50 col mr-2 mb-2" :disabled="!change">{{i + 1}}</button>
                                </div>
                                <div class="col-md-3">
                                    <div class="float-right timer pt-2 px-1 pb-0 rounded-lg bg-current">
                                        <div class="time-count" v-if="time >= 3600">
                                            <span class="text-time text-current">{{ formatedCountdown.split(":")[0] }}</span>
                                            <span class="text-day text-white">Hours</span>
                                        </div>
                                        <div class="time-count" v-else>
                                            <span class="text-time text-current">00</span>
                                            <span class="text-day text-white">Hours</span>
                                        </div>
                                        <div class="time-count" v-if="time >= 3600">
                                            <span class="text-time text-current">{{ formatedCountdown.split(":")[1] }}</span>
                                            <span class="text-day text-white">Min</span>
                                        </div>
                                        <div class="time-count" v-else-if="time < 3600 && time >= 60">
                                            <span class="text-time text-current">{{ formatedCountdown.split(":")[0] }}</span>
                                            <span class="text-day text-white">Min</span>
                                        </div>
                                        <div class="time-count" v-else>
                                            <span class="text-time text-current">00</span>
                                            <span class="text-day text-white">Min</span>
                                        </div>
                                        <div class="time-count" v-if="time >= 3600">
                                            <span class="text-time text-current">{{ formatedCountdown.split(":")[2] }}</span>
                                            <span class="text-day text-white">Sec</span>
                                        </div> 
                                        <div class="time-count"  v-else-if="time < 3600 && time >= 60">
                                            <span class="text-time text-current">{{ formatedCountdown.split(":")[1] }}</span>
                                            <span class="text-day text-white">Sec</span>
                                        </div> 
                                        <div class="time-count"  v-else-if="time < 60 && time >= 0">
                                            <span class="text-time text-current">{{ formatedCountdown.split(":")[0] }}</span>
                                            <span class="text-day text-white">Sec</span>
                                        </div> 
                                        <div class="time-count"  v-else>
                                            <span class="text-time text-current">00</span>
                                            <span class="text-day text-white">Sec</span>
                                        </div> 
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <form v-if="start" @submit.prevent="submitAnswer" class="card mb-5 d-block w-100 shadow-0 bg-transparent rounded-lg p-3 border-0 text-left question-div">
                        <div v-for="(item, i) in question" :key="i" class="card-body p-4 bg-white rounded-lg mb-3" v-show="current == i">
                            <div class="row">
                                <div class="col-7">
                                    <div class="bg-lightblue py-2 theme-dark-bg text-center" v-if="item.quest_type == 1">
                                        <video class="w-50 h-auto" controls controlsList="nodownload">
                                            <source :src=" item.path" type="video/mp4">
                                        </video>
                                    </div>
                                    <div style="min-height:25px !important" class="ql-editor mb-3 bg-lightblue p-3 theme-dark-bg">
                                        <div class="bg-lightblue py-2 theme-dark-bg" v-if="item.quest_type == 2">
                                            <div class="row">
                                                <div class="col-6">  
                                                    <button v-if="played[i] == true" type="button" disabled class="btn btn-lg btn-danger rounded-circle w50 h50 mr-2"><i class="fas fa-stop"></i></button>
                                                    <a v-else href="javascript:void(0)" @click="startAudio(i)" class="btn btn-lg btn-current rounded-circle w50 h50 mr-2"><i class="fas fa-play"></i></a>
                                                    <strong class="text-danger">Your online can play this audio once!</strong>
                                                </div>
                                                <div class="col">
                                                    <audio :ref="setItemRef(i)" class="w-50" controlsList="nodownload" muted="true">
                                                        <source :src=" item.path" type="audio/mpeg">
                                                    </audio>
                                                </div>
                                            </div>
                                        </div>
                                        <hr>
                                        <div class="ql-editor">
                                            <div v-html="item.question"></div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-5">
                                    <div v-if="item.submission_type == 1">
                                        <file-upload
                                        class="btn btn-current mr-1"
                                        @input="updateFile"
                                        @input-file="inputFile"
                                        :custom-action="fileAction"
                                        :data="{index: i}"
                                        :ref="setItemUploadRef(i)" 
                                        :input-id="setItemUploadRef(i)" 
                                        required>
                                        <i class="fa fa-plus"></i>
                                        Upload
                                        </file-upload>
                                        <a v-if="submission.answer[i]" :href=" submission.answer[i]" target="_blank" class="btn btn-success">Current File</a>

                                        <button type="button" class="btn btn-danger ml-1"  v-if="!$refs[`upload${i}`] || $refs[`upload${i}`][0].active" @click.prevent="$refs[`upload${i}`][0].active = false">
                                            <i class="fa fa-stop" aria-hidden="true"></i>
                                            Stop Upload
                                        </button>
                                    </div>
                                    <div v-if="item.submission_type == 2">
                                        <vue-editor v-model="submission.answer[i]" />
                                        <div class="clearfix"></div>
                                    </div>
                                    <div v-if="item.submission_type == 3">
                                        <div v-for="(opt, j) in item.option" :key="j" class="bg-lightgrey theme-dark-bg py-3 px-2 mt-3 style2 rounded-lg font-xssss fw-600 lh-28 text-grey-700 mb-0 p-2">
                                            <span class="py-2 px-3 mr-2 d-inline-block rounded-circle text-white font-xssss fw-600 radio-cont">
                                                <input v-model="submission.answer[i]" type="radio" :value="opt.id" :name="`radios[${i}]`">
                                            </span>
                                            <div class="d-inline-block">
                                                {{opt.option}}
                                            </div>
                                        </div>
                                    </div>
                                    <div v-if="item.submission_type == 4">
                                        <a href="javascript:void(0)" @click="submission.answer[i] = '1'" :class="{'btn-success' : submission.answer[i] == '1', 'btn-outline-success' : submission.answer[i] != '1'}" class="btn btn-lg mr-2">True</a>
                                        <a href="javascript:void(0)" @click="submission.answer[i] = '0'" :class="{'btn-danger' : submission.answer[i] == '0', 'btn-outline-danger' : submission.answer[i] != '0'}" class="btn btn-lg">False</a>
                                    </div>
                                    <div v-if="item.submission_type == 5">
                                        <div v-for="n in (item.question.split('[BLANK]').length - 1)" :key="n" class="form-group">
                                            <input @keyup="onChange(i)" v-model="item.arrAnswer[n-1]" type="text" class="form-control" :placeholder="'Input answer for [_____] ' + n">
                                        </div>
                                    </div>
                                    <div v-if="item.submission_type == 6">
                                        <table class="table m-0">
                                            <tr v-for="(opt, j) in item.option" :key="j">
                                                <td class="bg-grey">{{opt.option}}</td>
                                                <td>
                                                    <select  v-if="item.answer" @change="onChange(i)" v-model="item.arrAnswer[j]" class="form-control">
                                                        <option v-for="(val, k) in item.answer.split('*##*')" :key="k" :value="val">{{val}}</option>
                                                    </select>
                                                    <p v-else>No Answer!</p>
                                                </td>
                                            </tr>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="card-body p-3 bg-white rounded-lg mb-5">
                            <div class="row">
                                <div class="col-6">
                                    <button v-if="current != 0" @click="current -= 1" class="btn btn-lg btn-outline-primary fw-500" type="button">Prev</button>
                                </div>
                                <div class="col-6 text-right">
                                    <button v-if="current != question.length-1" @click="current += 1" class="btn btn-lg btn-primary fw-500 ml-2" type="button">Next</button>
                                    <button v-if="current == question.length-1" type="submit" class="btn btn-lg btn-current fw-500" :disabled="process || submission.answer.filter(x => x == null).length != 0">
                                        <div v-if="process" class="spinner-border text-white" style="width: 1.5rem; height: 1.5rem;" role="status">
                                            <span class="sr-only">Loading...</span>
                                        </div>
                                        {{ process ? '' : 'Submit' }}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
                <div class="col-12" v-else>
                    <div class="card w-100 p-3 bg-grey p-lg-3 border-0 mb-3 rounded-lg d-block float-left">
                        <div class="row">
                            <div class="col-12 text-center py-3">
                                <img :src="'/images/empty.png'" alt="" width="300">
                                <h1 class="text-muted">Data don't exist</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div v-else class="row">
                <div class="col-12">
                    <div class="row justify-content-center">
                        <div class="col-lg-6 col-md-8 text-center default-page">
                            <div class="card border-0 text-center d-block">
                                <img :src="'/images/404.png'" alt="icon" class="w300 mb-4 ml-auto mr-auto ">
                                <h1 class="fw-700 text-grey-900 display4-size display4-md-size">Page Not Found.</h1>
                                <p class="text-grey-500 font-xss">The page you're looking for isn't available. Try to search again or use the go to.</p>
                                <button @click="$router.go(-1)" class="p-3 border-0 w175 bg-current text-white d-inline-block text-center fw-600 font-xssss rounded-lg text-uppercase ls-3">Return Back</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-12" v-else>
            <div class="row">
                <div class="col-12 py-3 text-center">
                    <div>
                        <p>Loading All Questions, Please Wait</p>
                        <p>{{ Math.round(loadedQuestion / totalQuestion * 100) || 0 }} %</p>
                    </div>
                    <div class="my-3">
                        <div class="spinner-grow text-warning mx-3" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                        <div class="spinner-grow text-warning mx-3" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                        <div class="spinner-grow text-warning mx-3" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                        <div class="spinner-grow text-warning mx-3" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                        <div class="spinner-grow text-warning mx-3" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                        <div class="spinner-grow text-warning mx-3" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <modal name="audio-modal"
            :width="'350px'"
            :height="'auto'">
            <div class="container-fluid">
                <div class="row mb-0 mt-2 py-2">
                    <div class="col-12 px-4 py-0">
                        <a @click="closeModal()" href="javascript:void(0)" class="float-right"><i class="fas fa-times text-danger"></i></a>
                    </div>
                </div>
                <div class="row mb-2">
                    <div class="col-12"><hr></div>
                    <div class="col-12 text-center">
                        <audio controls>
                            <source :src=" submission.answer[current]" type="audio/webm">
                            Browsermu tidak mendukung tag audio, upgrade donk!
                        </audio>
                    </div>
                </div>
            </div>
        </modal>
    </div>
</template>

<script>
import axios from 'axios'
import moment from 'moment'
import "moment-duration-format";
import FileUpload from 'vue-upload-component'
export default {
    components: {
        FileUpload,
    },
    data(){
        return{
            time: 0,
            process: false,
            change: true,
            paramsId: this.$route.params.idCourse,
            assignmentId: this.$route.params.idAssignment,
            media : process.env.VUE_APP_URL_CLOUD,
            exist: true,
            start : false,
            isLoad: true,
            interval: null,
            current: 0,
            question: [],
            index:-1,
            files:[],
            played:[],
            upload:{
                path:[]
            },
            submission:{
                id:[],
                answer:[]
            },
            totalQuestion: 0,
            loadedQuestion: 0
        }
    },
    computed: {
        formatedCountdown() {
            return moment.duration(this.time, "seconds").format("hh:mm:ss");
        },
    },
    async created(){
        await this.getTotalQuestion()
        await this.getQuestions()
        // this.getAssigment()
    },
    beforeDestroy() {
        if(this.interval != null) {
            clearInterval(this.interval)
        }
    },
    methods: {
        async getTotalQuestion(){
            await axios.get(`${process.env.VUE_APP_URL_API}/core/assignment/v2/total-quest?slug=${this.paramsId}&id=${this.assignmentId}`, {
                headers: {
                    Authorization: `Bearer ${localStorage.token}`
                }
            }).then(res =>{
                this.totalQuestion = res.data.data
                this.question = Array(res.data.data).fill(null)
            })
        },
        shuffle(array) {
            array.sort(() => Math.random() - 0.5);
            return array;
        },
        onChange(i){
            this.submission.answer[i] = this.question[i].arrAnswer.join("*##*");
        },
        setAnswer(i, val){
            this.$set(this.submission.answer, i, val)
        },
        startAudio(index){
            this.played[index] = true,
            this.$refs[`audio${index}`][0].play()
        },
        showModal(){
            this.$modal.show('audio-modal')
        },
        closeModal(){
            this.$modal.hide('audio-modal')
        },
        startTest(){
            this.$swal({
                title: 'Achievement Test',
                text: 'Click button bellow to start test!',
                icon: 'info',
                confirmButtonText:'<i class="fa fa-clock mr-2"></i> Start!',
                showConfirmButton: true,
                allowOutsideClick: false,
                preConfirm: () => {
                    this.start = true
                    this.interval = setInterval(() => {
                        this.time -= 1;
                        if (this.time == 180){
                            this.$swal({
                                toast: true,
                                title: 'Achievement Test',
                                text: '3 minute left!',
                                icon: 'info',
                                position: 'top-end',
                                showConfirmButton: false,
                                timerProgressBar: true,
                            })
                        }
                        if (this.time == 60){
                            this.$swal({
                                toast: true,
                                title: 'Achievement Test',
                                text: 'A minute left!',
                                icon: 'info',
                                position: 'top-end',
                                showConfirmButton: false,
                                timer: 5000,
                                timerProgressBar: true,
                            })
                        }
                        if (!this.time) {
                            clearInterval(this.interval);
                            this.postAnswer()
                            this.$swal({
                                title: 'Achievement Test',
                                text: 'Time is up!',
                                icon: 'warning',
                                showConfirmButton: false,
                                allowOutsideClick: false,
                            })    
                        } 
                    }, 1000); 
                },
            })  
        },
        setItemRef(i) {
            return `audio${i}`;
        },
        setItemUploadRef(i) {
            return `upload${i}`; // Append `i` to the string
        },
        async submitAnswer(){
            this.$swal({
                title: 'Are you sure to submit this test?',
                text: 'You can\'t revert your action',
                showCancelButton: true,
                confirmButtonText: 'Yes Submit it!',
                cancelButtonText: 'No, Keep it!',
                showCloseButton: true,
                showLoaderOnConfirm: true,
            }).then(async(result) => {
                if(result.value) {
                    await this.postAnswer()
                }
            })
        },
        async postAnswer(){
            this.process = true
            const submission = this.question.map((item, i) => {
                return {
                    id: this.submission.id[i] ? this.submission.id[i] : null,
                    quest_id: item.id,
                    type: item.submission_type,
                    answer: this.submission.answer[i] ? this.submission.answer[i] : null,
                    corect: item.submission_type == 3 && this.submission.answer[i] == item.answer ? 1 : item.submission_type == 3 && this.submission.answer[i] != item.answer ? 2 : 0 ,
                };
            })
            var data = {
                slug: this.paramsId,
                assignment_id: this.assignmentId,
                submission: submission,
            }
            await axios.post(`${process.env.VUE_APP_URL_API}/core/assignment/quest/submit`, data, {
                headers: {
                    Authorization: `Bearer ${localStorage.token}`
                }
            }).then(res => {
                if(res.data.success) {
                    this.$router.push({ name: 'MyCourseAssessmentTaskAchievementTest', params:{idCourse : this.paramsId, idAssignment: this.assignmentId} })
                    this.$swal({
                        toast: true,
                        title: 'Achievement Test',
                        text: res.data.message,
                        icon: 'success',
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                    })
                } else {
                    this.$swal({
                        toast: true,
                        title: 'Achievement Test',
                        text: res.data.message,
                        icon: 'error',
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                    })
                }
            }).finally(() => this.process = false)
        },
        async getQuestions(){
            let limit = 5
            let offset = 0
            let stillLoading = true
            
            while(stillLoading){
                await axios.get(`${process.env.VUE_APP_URL_API}/core/assignment/v2/quest?slug=${this.paramsId}&id=${this.assignmentId}&get_type=start&limit=${limit}&offset=${offset}`, {
                    headers: {
                        Authorization: `Bearer ${localStorage.token}`
                    }
                }).then(res => {
                    let threshold = Math.min(res.data.data.length, limit)
                    if(res.data.data.length > 0){
                        for(let i = 0; i < threshold; i++){
                            this.question[i + offset] = res.data.data[i]
                            if(this.question[i + offset].submission_type == 6){
                                if(this.question[i + offset].answer) {
                                    let suffled = this.shuffle(this.question[i + offset].answer.split('*##*'))
                                    this.question[i + offset].answer = suffled.join('*##*')
                                }
                            }
                            this.question[i + offset].arrAnswer = []
                            if(this.question[i + offset].ans != null) {
                                this.submission.id[i + offset] = this.question[i + offset].ans.id
                                this.submission.answer[i + offset] = this.question[i + offset].ans.answer
                            } else {
                                if(this.question[i + offset].submission_type == 7) {
                                    this.submission.answer[i + offset] = this.question[i + offset].question
                                } else {
                                    this.submission.id[i + offset] = null
                                    this.submission.answer[i + offset] = null
                                }
                            }
                        }
                        this.loadedQuestion = this.loadedQuestion + res.data.data.length
                        offset = offset + limit
                    }else{
                        this.isLoad = false
                        this.time = res.data.assignment.time * 60
                        stillLoading = false
                        this.startTest()
                    }
                })
            }
        },
        async getAssigment(){
            await axios.get(`${process.env.VUE_APP_URL_API}/core/assignment/quest?slug=${this.paramsId}&id=${this.assignmentId}&get_type=start`, {
                headers: {
                    Authorization: `Bearer ${localStorage.token}`
                }
            }).then(res => {
                if (res.data.success) {
                    this.time = res.data.assignment.time * 60
                    this.question = res.data.data
                    this.question.map((item, index) => {
                        if(item.submission_type == 6) {
                            if(item.answer) {
                                let suffled = this.shuffle(item.answer.split('*##*'))
                                item.answer = suffled.join('*##*')
                            }
                        }
                        item.arrAnswer = []
                        if(item.ans != null) {
                            this.submission.id[index] = item.ans.id
                            this.submission.answer[index] = item.ans.answer
                        } else {
                            if(item.submission_type == 7) {
                                this.submission.answer[index] = item.question
                            } else {
                                this.submission.id[index] = null
                                this.submission.answer[index] = null
                            }
                        }
                    })
                    this.isLoad = false
                    this.startTest()
                } else {
                    this.$swal({
                        toast: true,
                        title: 'Achievement Test',
                        text: res.data.message,
                        icon: 'error',
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                    })
                }
            }).catch(err => {
                if (err.response.status == 404) {
                    this.isLoad = false
                    this.exist = false
                }
            })
        },
        updateFile(value) {
            this.files[value[0].data.index] = value[0]
        },
        async fileAction(file) {
            return this.postFileUpload(file)
        },
        async inputFile(newFile, oldFile) {
            if (!newFile && oldFile) {
                if (oldFile.success && oldFile.response.path) {
                    // this.deleteFile(oldFile)
                }
            }
            if (Boolean(newFile) !== Boolean(oldFile) || oldFile.error !== newFile.error) {
                if (!this.$refs[`upload${newFile.data.index}`][0].active) {
                    this.$refs[`upload${newFile.data.index}`][0].active = true
                }
            }
        },
        onStream(){
            this.change = false
        },
        onResult (data) {
            var file = new File([data], 'audio-' + this.current + '.mp3', {type: 'audio/mpeg', lastModified: Date.now()});
            this.postFile(file);
        },
        async postFile(file){
            let data = new FormData();
            data.append('dir', 'submission');
            data.append('attachment', file);
            data.append('_token', 'elingwaysecretkey');
            await axios.post(`${process.env.VUE_APP_URL_MEDIA}/api/storage/store-cloud`, data, {withCredentials: false}, {
                headers: {
                    'Accept': 'application/json',
                    'content-type': 'multipart/form-data',
                    'Authorization': `Bearer ${localStorage.token}`
                },
            }).then(res => {
                this.submission.answer[this.current] = res.data.path
                this.change = true
                return res.data
            }).catch(err => {
                console.log(err)
            })
        },
        async postFileUpload(file){
            let data = new FormData();
            data.append('dir', 'submission');
            data.append('attachment', file.file);
            data.append('_token', 'elingwaysecretkey');
            await axios.post(`${process.env.VUE_APP_URL_MEDIA}/api/storage/store-cloud`, data, {withCredentials: false}, {
                headers: {
                    'Accept': 'application/json',
                    'content-type': 'multipart/form-data',
                    'Authorization': `Bearer ${localStorage.token}`
                },
            }).then(res => {
                file.response = res.data
                this.submission.answer[file.data.index] = res.data.path
                return res.data
            })
        },
        async deleteFile(index){
            let data = new FormData();
            data.append('path', this.submission.answer[index]);
            data.append('_token', 'elingwaysecretkey');
            await axios.post(`${process.env.VUE_APP_URL_MEDIA}/api/storage/delete`, data, {withCredentials: false}, {
                headers: {
                    'Accept': 'application/json',
                    'content-type': 'multipart/form-data',
                    'Authorization': `Bearer ${localStorage.token}`
                },
            }).then(res => {
                this.submission.answer[this.current] = ''
                return res.data
            })
        },
    }
}
</script>
<style scoped>
    .radio-cont input[type='radio'] {
        transform: scale(2.2);
        visibility: hidden;
    }
    .radio-cont input[type='radio']:after {
        width: 100%;
        height: 100%;
        cursor: pointer;
        border-radius: 50%;
        top: 0;
        left: 0;
        position: absolute;
        background-color: #d1d3d1;
        content: '';
        visibility: visible;
        border: none;
    }

    .radio-cont input[type='radio']:checked:after {
        width: 100%;
        height: 100%;
        border-radius: 50%;
        top: 0;
        left: 0;
        position: absolute;
        background-color: #ffa500;
        content: '';
        display: inline-block;
        visibility: visible;
        border: none;
    }
    ::v-deep .ql-editor img {
        width: 100% !important;
    }
</style>


